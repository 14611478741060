import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import PostLink from "../components/post-link";
import SEO from "../components/seo";

const IndexPage = ({ data: { site, posts, videos } }) => {
  const screencastLinks = videos.edges.map((edge) => (
    <PostLink key={edge.node.id} post={edge.node} />
  ));

  const postLinks = posts.edges.map((edge) => (
    <PostLink key={edge.node.id} post={edge.node} />
  ));

  return (
    <Layout>
      <SEO title={site.siteMetadata.description} />
      <div className="mx-auto prose lg:prose-xl dark:prose-invert prose-a:text-indigo-600 dark:prose-a:text-indigo-400">
        <h1 className="hidden">{site.siteMetadata.description}</h1>

        <p className="mt-8 font-semibold text-lg">
          I'm <a href="https://twitter.com/zac_siegel">@zac_siegel</a>, a
          software architect and entrepeneur.
        </p>

        <p className="mt-4">
          I share my ideas on writing software with{" "}
          <a href="/articles">articles</a>, <a href="/videos">videos</a>,{" "}
          <a href="https://youtube.com/@zsiegel87">live coding</a> and speaking
          at conferences.
        </p>

        <p className="mt-4">
          I am currently focused on building software leveraging the JVM,
          Kotlin, and TypeScript.
        </p>

        <p className="mt-4">
          Want to get in touch about a project or idea? Have a suggestion on a
          topic for an article or screencast? Send me a message on{" "}
          <a href="https://twitter.com/zac_siegel">Twitter</a> or{" "}
          <a rel="me" href="https://zsiegel.social/@zac">Mastodon</a> or{" "}
          <a href="https://www.linkedin.com/in/zachary-siegel-a4952824/)">
            LinkedIn
          </a>
          .
        </p>

        <section className="article-list">
          <h2 className="mt-8 mb-1 text-2xl font-bold">Recent Articles</h2>
          {postLinks}
        </section>
        <section className="article-list">
          <h2 className="mt-8 mb-1 text-2xl font-bold">Recent Videos</h2>
          {screencastLinks}
        </section>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site: site {
      siteMetadata {
        title
        description
        author
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [fields___date] }
      filter: { fields: { type: { eq: "post" } } }
      limit: 3
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          fields {
            slug
            date
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
    videos: allMarkdownRemark(
      sort: { order: DESC, fields: [fields___date] }
      filter: { fields: { type: { eq: "video" } } }
      limit: 3
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          fields {
            slug
            date
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;

export default IndexPage;
